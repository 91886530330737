@tailwind base;
@tailwind components;
@tailwind utilities;

html {
	@apply h-screen select-none font-["Open_Sans"];
}

body,
#root {
	@apply min-h-full;
}

html {
	@apply bg-gray-300 dark:bg-gray-950 text-black dark:text-white h-screen;
}

body {
	@apply overflow-y-scroll;
}
